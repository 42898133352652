import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, List, Span } from "../components/Core";
import banner1 from "../assets/image/png/sample-banner1.png";
import banner2 from "../assets/image/png/sample-banner2.png";
import banner3 from "../assets/image/png/sample-banner3.png";
import banner4 from "../assets/image/png/sample-banner4.png";

const TermsConditions = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <Title className="text-center">
                  Kesto Mobile Ads
                </Title>
                <Text variant="small" mb="5px">
                  Kesto Ads is the premier mobile ad platform designed exclusively for the Ethiopian and Eritrean community, available only through the Kesto App. Our platform delivers highly targeted, location-aware ads that engage and convert like never before.
                </Text>

                <Text variant="small" mb="5px">
                  Unlike traditional print or web-based advertising, Kesto Ads offers unique mobile ad sizes and formats seamlessly integrated into our app. Our cutting-edge ad serving technology ensures maximum relevance and engagement, tailored to your target audience’s location. Plus, we provide detailed analytics so you can measure the effectiveness of your campaigns with precision.
                </Text>
                <Text variant="small" mb="5px">
                  Don't miss out on this unparalleled opportunity to connect with your audience.
                </Text>
                <Text variant="small" mb="5px">
                  Call us now at <a href="dial:650-924-2374"><Span color="primary">650-924-2374</Span></a>  or email <a href="mailto:support@kesto.io"><Span color="primary">  support@kesto.io</Span></a> to get started!
                </Text>

                <Box mt={["40px", null, "50px"]}>
                  <Text mb="10px" color="black">
                    <b>
                      Upcoming Special Events in July 2024:
                    </b>
                  </Text>
                  <List>
                    <li>
                      <Span>ESFNA 41 Atlanta Soccer Games: June 26 - July 7, 2024, in Atlanta GA</Span>
                    </li>
                    <li>
                      <Span>Empower Community Event: July 27, 2024, in Washington DC</Span>
                    </li>
                  </List>
                  <Text variant="small" mb="10px">
                    These events present a perfect opportunity for businesses to reach a large and engaged audience through Kesto Ads.
                  </Text>
                  <Text variant="small" mb="5px">
                    <b>Special Ad Campaign Details: </b>Our ad campaign for ESFNA and Empower will run from now through the entire month of July 2024. Each ad placement includes a call to action that directs users to a Kesto business listing page with features such as one-click phone calls, one-click directions, website links, social media links, and one-click Uber directions.
                  </Text>

                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Why Choose Kesto Ads?
                  </Title>
                  <Text variant="small">
                    <Span>1. Niche Audience: Reach a highly targeted demographic of Ethiopians and Eritreans worldwide.</Span> <br/>
                    <Span>2. Custom Ad Formats: Utilize unique ad sizes specifically designed for mobile devices.</Span> <br/>
                    <Span>3. High Engagement: Benefit from high visibility and interaction rates within our app.</Span> <br/>
                    <Span>4. Detailed Analytics: Receive comprehensive statistics on impressions, clicks, and more to gauge your campaign's success.</Span> <br/>
                  </Text>
                  
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Target Businesses for Kesto Ads:
                  </Title>
                  <List>
                    <li>
                      Real Estate developers, repair services, and brokers
                    </li>
                    <li>
                      Authors and publishers
                    </li>
                    <li>
                      Financial services
                    </li>
                    <li>
                      Career and educational services
                    </li>
                    <li>
                      Food and spice providers
                    </li>
                    <li>
                      Restaurants, cafes, bars, and lounges
                    </li>
                    <li>
                      Hairdressers and salons
                    </li>
                    <li>
                      Specialty grocery stores
                    </li>
                    <li>
                      Non-profit organizations
                    </li>
                    <li>
                      Career and educational agencies
                    </li>
                    <li>
                      Legal service providers
                    </li>
                    <li>
                      Art sellers
                    </li>
                    <li>
                      Healthcare providers and doctors
                    </li>
                    <li>
                      Clothing providers
                    </li>
                    <li>
                      Jewelry and clothing sellers
                    </li>
                  </List>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Text variant="small">
                    <b>Ad Campaign Details: </b>Our ad campaign will run from now through the entire month of July 2024. Each ad placement includes a call to action that directs users to a Kesto business listing page with features such as one-click phone calls, one-click directions, website links, social media links, and one-click Uber directions.
                  </Text>
                </Box>

                

                <Box mt={["40px", null, "50px"]}>
                  <Text mb="10px" color="black">
                    For Ad Formats and Rates on Kesto Ads reach out to us:
                  </Text>
                  <Text variant="small" mb="5px">
                    Call us now at <a href="dial:650-924-2374"><Span color="primary">650-924-2374</Span></a>  or email <a href="mailto:support@kesto.io"><Span color="primary">  support@kesto.io</Span></a> to get started!
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Sample Landing Banner
                  </Title>
                  <Row className="justify-content-center text-center">
                    <Col md="6" className="mb-5">
                      <img src={banner1} alt="" className="img-fluid" />
                    </Col>
                    <Col md="6" mb="10px" className="mb-5">
                      <img src={banner2} alt="" className="img-fluid" />
                    </Col>
                    <Col md="6" mb="10px" className="mb-5">
                      <img src={banner3} alt="" className="img-fluid" />
                    </Col>
                    <Col md="6" mb="10px" className="mb-5">
                      <img src={banner4} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </Box>            
                

              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default TermsConditions;
